import { defineStore } from "pinia";
import { subMonths, isAfter, startOfDay, endOfDay } from "date-fns";
import hash from "object-hash";
import { useSearchFiltersStore } from "./searchFilter";
import { useChartsStore } from "./charts";
import { useOrgStore } from "./org";
import { useExclusion } from "./exclusion.ts";
import SearchState, {
  ComparisonSetItem,
  SavedSearchItem,
  SearchKeywordsGroup,
  SearchKeywordList,
  MentionList,
  SearchKeyword,
} from "~/types/SearchStateType";
import Mention from "~/types/MentionType";
import { useManageStore } from "~/store/manage";
import DateRange from "~/types/DateRangeType";
import { useApiCall } from "~/composables/useApiCall";
import { useRequestQuery } from "~/composables/useRequestQuery";
import SearchFilter from "~/types/SearchFilterType";
import { TopicKeyphrase } from "~/types/attributes/TopicsDataAttributesType";
import RequestOptions from "~/types/RequestOptionsType";

export const useSearchStore = defineStore("search", {
  state: (): SearchState => ({
    searches: [],
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
    selectedMentions: new Set(),
    selectedMentionIds: new Set(),
    nestedSearches: [],
    nestedSearchesFilters: [],
    selectedSearch: {},
    selectedNestedSearch: {},
    mentions: [],
    limit: 100,
    page: 1,
    total: 0,
    isPaginated: false,
    searchType: 0, // Handles UI, 0: keywords, 1: boolean query
    querySearchType: 14, // Handles search type passed in request payload, 0: article, 1: chart, 14: adhoc
    searchText: "",
    searchKeywords: {
      any: [],
      all: [],
      none: [],
    },
    addedSearchKeywords: {
      any: [],
      all: [],
      none: [],
    },
    removedSearchKeywords: [],
    currentSavedSearchKeywords: [],
    currentSearchDashboardKeywords: [],
    hasCaseBrand: false,
    chartData: {},
    topicsData: null,
    isTopicsDataLoading: false,
    currentSelectedSearchDashboard: null, // comparison set
    currentSelectedProfile: null, // nested search
    showLegacyWarningDialog: false,
    showExcludeDialog: false,
    mentionExclusionNotification: false,
    excludeMentionData: {},
    legacyDataHolder: {},
    filterKeywords: "",
    currentRoute: "",
    originalSearchHash: "6cf1b193adbdd637b67a45dc88e295f196e71658",
    allOrgNestedSearches: [],
    currentTotalProfiles: 0,
    dateRangeErrMsg: "",
    searchDashErrMsg: "",
    isLoading: false,
    isUnsavedNestedSearch: false,
    originalLicenseLimitHash: "",
    nestedSearchDialogError: [],
    isUntitledSearch: false,
    isNotAccesptedSearch: false,
    showAddToReport: false,
    addToReportId: 0,
    isDuplicateSearch: false,
  }),
  getters: {
    searchIsEmpty: (state) => {
      if (state.searchType === 0) {
        const keys: Array<"any" | "all" | "none"> = ["any", "all", "none"];
        return keys.every((key: "any" | "all" | "none") => {
          return !state.searchKeywords[key].length;
        });
      } else {
        return !state.searchText.trim();
      }
    },
    nestedSearchesByComparisonSetId: (state) => {
      const res = new Map();
      state.searches.forEach((search: ComparisonSetItem) => {
        const data: Array<{ id: String; type: String }> =
          search.relationships.profiles.data;
        data.forEach((profile: { id: String; type: String }) => {
          const profileData = state.allOrgNestedSearches.find(
            (profileData: SavedSearchItem) => profileData.id === profile.id,
          );

          if (res.has(search.id)) {
            res.set(search.id, [...res.get(search.id), profileData]);
          } else {
            res.set(search.id, [profileData]);
          }
        });
      });
      return res;
    },
    onSearchPageCheck: (state) => {
      return state.currentRoute === "search";
    },
    onMentionsPageCheck: (state) => {
      return state.currentRoute === "mentions";
    },
    onTopicsPageCheck: (state) => {
      return state.currentRoute === "topics";
    },
    onChartsPageCheck: (state) => {
      return state.currentRoute === "charts";
    },
    hasUnsavedChanges: (state) => {
      const currentSearchHash = hash({
        keywords: state.searchKeywords,
        searchtext: state.searchText,
        name: state.selectedSearch.name ? state.selectedSearch.name : "",
      });
      return currentSearchHash !== state.originalSearchHash;
    },
    isSavedSearch: (state) => {
      return state.selectedSearch.name && state.searchText.length > 0;
    },
    showTopicsPageCharts: (state) => {
      const arrayOfObjects = Object.entries(
        state?.topicsData?.attributes?.search_result?.counts?.keyphrases ?? {},
      );

      return arrayOfObjects.length > 0;
    },
    getTopicsKeyphrases: (state) => {
      const arrayOfObjects = (
        Object.entries(
          state?.topicsData?.attributes?.search_result?.counts?.keyphrases ??
            {},
        ) as [string, TopicKeyphrase][]
      ).map(([key, value]) => ({
        title: key,
        total: value.total,
        aggregations: value.aggregations,
      }));

      // Sort the array by the 'total' property in descending order
      arrayOfObjects.sort((a, b) => b.total - a.total);

      // Pick the first 20 elements
      return arrayOfObjects.slice(0, 20);
    },
    dateRangeError: (state) => {
      return state.dateRangeErrMsg;
    },
    searchNameExists: (state) => {
      return state.searches.some(
        (search: ComparisonSetItem) =>
          search.attributes.name === state.selectedSearch.name?.trim(),
      );
    },
    hasLicenseLimitsChanged: (state) => {
      return (
        state.originalLicenseLimitHash !==
        hash(
          state.nestedSearches.map((search) => ({
            id: search.id,
            name: search.attributes.name,
          })),
        )
      );
    },
    getSearchesWithProfiles: (state) => {
      const searchesWithProfiles = state.searches.map((search) => {
        const profiles = search.relationships.profiles.data.map((profile) => {
          return state.allOrgNestedSearches.find(
            (pro) => pro.type === "profile" && pro.id === profile.id,
          );
        });
        return {
          ...search,
          relationships: {
            ...search.relationships,
            profiles: {
              ...search.relationships.profiles,
              data: profiles,
            },
          },
        };
      });
      return searchesWithProfiles;
    },
    getCurrentSelectedProfileName: (state) => {
      const profile = state.allOrgNestedSearches.find(
        (profile) =>
          profile.type === "profile" &&
          profile.id === state.currentSelectedProfile,
      );

      return profile?.attributes?.name || "";
    },
    mostRecents: (state) => {
      const weekAgo = startOfDay(
        new Date(new Date().setDate(new Date().getDate() - 7)),
      );
      const todayEnd = endOfDay(new Date());

      const filteredResults = state.mentions.filter((mention) => {
        const pubDate = new Date(mention.published_on);
        return pubDate >= weekAgo && pubDate <= todayEnd;
      });

      if (filteredResults.length > 10) {
        return filteredResults.slice(0, 10);
      } else {
        return filteredResults;
      }
    },
  },
  actions: {
    setCurrentSelectedSearchDashboard(searchDashboard: any) {
      this.currentSelectedSearchDashboard = searchDashboard.id;
      localStorage.setItem(
        "currentSearchDashboard",
        JSON.stringify({
          data: {
            id: searchDashboard.id,
            type: searchDashboard.type,
          },
        }),
      );
    },
    setCurrentSelectedProfile(profile: any) {
      const searchDashboard: { id: string; type: string } | undefined =
        this.searches.find(
          (search) =>
            search.type === profile.relationships.search_dashboard.data.type &&
            search.id === profile.relationships.search_dashboard.data.id,
        );
      this.setCurrentSelectedSearchDashboard(searchDashboard);
      this.currentSelectedProfile = profile.id;
      localStorage.setItem(
        "currentProfile",
        JSON.stringify({
          data: {
            id: profile.id,
            type: profile.type,
          },
        }),
      );
    },
    getComparisonSetById(searchId: string): ComparisonSetItem | undefined {
      return this.searches.find(
        (search: ComparisonSetItem) => search.id === searchId,
      );
    },
    getNestedSearchById(nestedSearchId: string): SavedSearchItem | undefined {
      return this.nestedSearches.find(
        (nestedSearch: SavedSearchItem) => nestedSearch.id === nestedSearchId,
      );
    },
    async updateSearchSelection(
      search: ComparisonSetItem,
      useDefaultFilter: boolean = false,
    ) {
      const chartsStore = useChartsStore();

      this.page = 1;
      this.selectedSearch = {
        id: search?.id,
        ...search?.attributes,
        primaryProfileId:
          search?.relationships?.primary_profile?.data?.id ??
          search?.relationships?.profiles?.data?.[0]?.id,
      };
      this.filterKeywords = "";
      await this.fetchNestedSearches(true, "", useDefaultFilter);
      await chartsStore.fetchChartsByTemplateID();
      this.selectedMentionIds = new Set();
      this.selectedMentions = new Set();
      this.generateOriginalHash();
    },
    updateSearchName(name: string) {
      this.selectedSearch.name = name;
      if (!this.selectedSearch?.is_comparison) {
        this.selectedNestedSearch.name = name;
      }

      this.isDuplicateSearch = this.searches.some((item: any) => {
        return item.attributes.name === this.selectedSearch.name?.trim();
      });
    },
    setDefaultSearchFilters() {
      // sets the search filter associated with the primary profile to current nested search
      const searchFiltersStore = useSearchFiltersStore();
      let searchFilter = null;
      for (const filter of this.nestedSearchesFilters) {
        if (
          filter?.relationships?.profile?.data?.id ===
            this.selectedSearch?.primaryProfileId &&
          filter?.attributes
        ) {
          searchFilter = {
            id: filter.id,
            ...filter.attributes,
          };
          break;
        }
      }
      if (
        !searchFilter &&
        searchFiltersStore.searchFilters.has(
          this.selectedSearch?.primaryProfileId,
        )
      ) {
        searchFilter = searchFiltersStore.searchFilters.get(
          this.selectedSearch.primaryProfileId,
        );
      }
      searchFiltersStore.setCurrentSearchFilter(searchFilter, false);
    },
    updateDateRange(newDateRange: DateRange) {
      this.startDate = newDateRange.start;
      this.endDate = newDateRange.end;

      const maxLimit = subMonths(new Date(), 13);
      const planMaxLimit = isAfter(this.startDate, maxLimit);

      if (!planMaxLimit) {
        this.dateRangeErrMsg = "search.errors.exceedDateRange";
        return;
      }

      this.dateRangeErrMsg = "";
      localStorage.setItem(
        "sessionDateRange",
        JSON.stringify({
          start: newDateRange.start,
          end: newDateRange.end,
        }),
      );

      // Reset the topics data
      this.topicsData = null;

      // We only want to fetch charts on the mentions page
      if (this.onMentionsPageCheck) {
        const temp = this.querySearchType;
        this.updateQuerySearchType(1);
        this.fetchChartData();
        this.updateQuerySearchType(temp);
      }

      // We only want to fetch topics data on the topics page
      if (this.onTopicsPageCheck) {
        const temp = this.querySearchType;
        this.updateQuerySearchType(3);
        this.fetchTopicSearchesData();
        this.updateQuerySearchType(temp);
      }

      this.articleSearchRequest();
    },
    updateKeywords(keywords: SearchKeywordsGroup) {
      this.searchKeywords = keywords;
    },
    updateSelectedNestedSearch(search: SavedSearchItem) {
      this.isUnsavedNestedSearch = false;
      this.page = 1;
      this.selectedNestedSearch = {
        ...search?.attributes,
        id: search?.id,
      };
      const searchType = search?.attributes?.search_type || 0;
      this.updateSearchType(searchType);
      this.updateQuerySearchType(0);
      this.currentSelectedProfile = search?.id;
      // wipe the stored adhoc data if we're loading a saved search
      localStorage.removeItem("localKeywords");
      localStorage.removeItem("localBooleanData");

      this.addedSearchKeywords = {
        any: [],
        all: [],
        none: [],
      };
      this.hasCaseBrand = false;
      this.removedSearchKeywords = [];
      this.topicsData = null;

      this.filterSavedSearchKeywords();

      this.loadKeywords(this.currentSearchDashboardKeywords, search?.id);
      const currentComparisonSetId = this.selectedSearch.id;
      if (currentComparisonSetId && search?.id) {
        this.updateUsersCurrentInfo(currentComparisonSetId, search?.id);
      }

      if (search?.attributes?.profile_search_text) {
        this.searchText = search?.attributes?.profile_search_text;
      }

      localStorage.setItem(
        "currentProfile",
        JSON.stringify({
          data: { id: search?.id, type: "profile" },
        }),
      );
      if (!this.searchIsEmpty) {
        this.articleSearchRequest();
      }
      this.generateOriginalHash();
    },

    filterSavedSearchKeywords() {
      this.currentSavedSearchKeywords =
        this.currentSearchDashboardKeywords?.filter(
          (item) =>
            item?.relationships?.profile?.data?.id ===
            this.selectedNestedSearch?.id,
        );
    },

    loadKeywords(
      keywordsArray: SearchKeywordList,
      nestSearchId: string | null = null,
    ) {
      this.searchKeywords = {
        any: [],
        all: [],
        none: [],
      }; // clear it first

      keywordsArray?.forEach((keyword) => {
        if (keyword.relationships.profile.data.id === nestSearchId) {
          // only load the matching search keywords
          switch (keyword.attributes.keyword_type) {
            case 1:
              // handle CASE Brand
              // * For now lets at least load them up
              this.searchKeywords.all.push(keyword.attributes.keyword);
              // not being used right now, will wait for product/design on how to handle loading case keywords
              // 1 - if Case exists in searchText from db, switch to boolean mode
              // 2 - show keyword search with a warning about Case keywords
              this.hasCaseBrand = true;
              break;
            case 2:
              this.searchKeywords.any.push(keyword.attributes.keyword);
              break;
            case 3:
              this.searchKeywords.all.push(keyword.attributes.keyword);
              break;
            case 4:
              this.searchKeywords.none.push(keyword.attributes.keyword);
              break;
          }
        }
      });

      // Here we should fetch all of the keywords for the current profile
    },

    generateSearchText() {
      // TODO: Handle legacy Brand searches

      // only excluding + for now
      const regex = /[+]/g;
      // let casedSearchText = this.casedKeywords.reduce((searchText, term) => {
      //   return (searchText += `"${term.keyword.replace(regex, "")}" OR `);
      // }, "");
      // casedSearchText = casedSearchText.slice(0, -4);

      let inclusiveSearchText = this.searchKeywords.all.reduce(
        (searchText: string, term: string) => {
          return (searchText += `"${term.replace(regex, "")}" AND `);
        },
        "",
      );
      inclusiveSearchText = inclusiveSearchText.slice(0, -4);

      let exclusiveSearchText = this.searchKeywords.none.reduce(
        (searchText: string, term: string) => {
          return (searchText += `"${term.replace(regex, "")}" OR `);
        },
        "",
      );
      exclusiveSearchText = exclusiveSearchText.slice(0, -4);

      let anySearchText = this.searchKeywords.any.reduce(
        (searchText: string, term: string) => {
          return (searchText += `"${term.replace(regex, "")}" OR `);
        },
        "",
      );
      anySearchText = anySearchText.slice(0, -4);

      let searchResult = "";
      // searchResult += this.casedKeywords.length ? `CASE (${casedSearchText}) ` : "";
      searchResult += this.searchKeywords.all.length
        ? `AND (${inclusiveSearchText}) `
        : "";
      searchResult += this.searchKeywords.any.length
        ? `AND (${anySearchText}) `
        : "";
      searchResult += this.searchKeywords.none.length
        ? `NOT (${exclusiveSearchText})`
        : "";
      this.searchText =
        searchResult.slice(0, 4) === "AND "
          ? searchResult.substring(4)
          : searchResult;
    },
    updateSearchText(text: string, update: boolean = false) {
      this.searchText = text;
      if (update && this.querySearchType === 14) {
        // if we're updating the search text via boolean mode instead of loading from saved search
        localStorage.setItem(
          "localBooleanData",
          JSON.stringify(this.searchText),
        );
      }
    },
    updateSearchType(searchId: number) {
      this.searchType = searchId;
    },
    updateQuerySearchType(querySearchType: number) {
      this.querySearchType = querySearchType;
    },
    updateMentions(mentionList: MentionList) {
      this.mentions = mentionList;
    },
    updateMention(updatedMention: Mention) {
      this.mentions = this.mentions.map((mention) => {
        if (mention.id === updatedMention.id) {
          return updatedMention;
        } else {
          return mention;
        }
      });
    },
    handleAddKeyword(listName: "any" | "all" | "none", input: string) {
      // TODO: Update profile keywords via API
      this.searchKeywords[listName].push(input);
      this.addedSearchKeywords[listName].push(input);
      if (this.querySearchType === 14) {
        localStorage.setItem(
          "localKeywords",
          JSON.stringify(this.searchKeywords),
        );
      }
    },
    handleRemoveKeyword(listName: "any" | "all" | "none", index: number) {
      // addedSearchKeywords / removedSearchKeywords keep track of any changes from
      // our original search. If the keyword being removed is new, we don't need to make
      // a call because it hasn't been saved yet
      const idx = this.addedSearchKeywords[listName].indexOf(
        this.searchKeywords[listName][index],
      );
      if (idx !== -1) {
        this.addedSearchKeywords[listName].splice(idx, 1);
      } else {
        const word = this.searchKeywords[listName][index];
        const keywordObj = this.currentSavedSearchKeywords.find((item) => {
          return item?.attributes?.keyword === word;
        });
        if (keywordObj?.id) {
          this.removedSearchKeywords.push(keywordObj.id);
        }
      }
      this.searchKeywords[listName].splice(index, 1);
      localStorage.setItem(
        "localKeywords",
        JSON.stringify(this.searchKeywords),
      );
    },

    handleClearKeywordsSection(listName: "any" | "all" | "none") {
      // Before clearing the keywords for a given section we need to:
      this.searchKeywords[listName].forEach((keyword: string) => {
        // Check if it was actually saved yet
        const idx = this.addedSearchKeywords[listName].indexOf(keyword);
        if (idx !== -1) {
          this.addedSearchKeywords[listName].splice(idx, 1);
        } else {
          // if it wasn't, flag the keyword as a removed keyword
          const keywordObj = this.currentSavedSearchKeywords.find((item) => {
            return item?.attributes?.keyword === keyword;
          });
          if (keywordObj?.id) {
            this.removedSearchKeywords.push(keywordObj.id);
          }
        }
      });
      // finally, clear the search keywords for the desired section
      this.searchKeywords[listName] = [];
      localStorage.setItem(
        "localKeywords",
        JSON.stringify(this.searchKeywords),
      );
    },

    selectRow(index: string) {
      if (this.selectedMentions.has(index)) {
        this.selectedMentions.delete(index);
      } else {
        this.selectedMentions.add(index);
      }
      this._resetSet(this.selectedMentions, "selectedMentions");
    },

    deselectAllMentions() {
      this.selectedMentionIds = new Set();
      this.selectedMentions = new Set();
    },

    selectAllMentions(indices: Array<string>, ids: Array<string>) {
      indices.forEach((index) => this.selectedMentions.add(index));
      ids.forEach((id) => this.selectedMentionIds.add(id));
      this._resetSet(this.selectedMentions, "selectedMentions");
      this._resetSet(this.selectedMentionIds, "selectedMentionIds");
    },

    handleSelectGroup(indices: Array<string>) {
      const hasAllIndices = indices.every((index: string) => {
        return this.selectedMentions.has(index);
      });
      if (hasAllIndices) {
        this.deselectGroupedArticles(indices);
      } else {
        this.selectGroupedArticles(indices);
      }
    },

    selectGroupedArticles(indicies: Array<string>) {
      indicies.forEach((index: string) => {
        this.selectedMentions.add(index);
      });
      this._resetSet(this.selectedMentions, "selectedMentions");
    },
    deselectGroupedArticles(indicies: Array<string>) {
      indicies.forEach((index: string) => {
        this.selectedMentions.delete(index);
      });
      this._resetSet(this.selectedMentions, "selectedMentions");
    },
    beginAdhocSearch() {
      localStorage.removeItem("localBooleanData");
      this.updateSearchName("");
      // omitted start/end date using defaults
      this.total = 0;
      this.mentions = [];
      this.nestedSearches = [];
      this.selectedSearch = {};
      this.selectedMentionIds = new Set();
      this.selectedMentions = new Set();
      this.selectedNestedSearch = {};
      this.selectedNestedSearch.created_at = new Date().toDateString();
      this.updateSearchType(0);
      this.updateQuerySearchType(14);
      this.searchText = "";
      this.searchKeywords = {
        any: [],
        all: [],
        none: [],
      };
      this.addedSearchKeywords = {
        any: [],
        all: [],
        none: [],
      };
      this.removedSearchKeywords = [];
      this.currentSavedSearchKeywords = [];
      this.currentSearchDashboardKeywords = [];
      this.chartData = {};
      this.topicsData = null;
      this.hasCaseBrand = false;
      this.generateOriginalHash();

      // set default search filter on loading up an adhoc search
      const searchFiltersStore = useSearchFiltersStore();
      searchFiltersStore.setCurrentSearchFilter(null);
    },

    beginNewNestedSearch(search: SavedSearchItem) {
      localStorage.removeItem("localBooleanData");
      this.isUnsavedNestedSearch = true;
      // omitted start/end date using defaults
      this.total = 0;
      this.mentions = [];
      this.nestedSearches.push(search);
      this.selectedNestedSearch = search?.attributes;
      this.selectedNestedSearch.created_at = new Date().toDateString();
      this.currentSelectedProfile = search.id;
      this.selectedMentionIds = new Set();
      this.selectedMentions = new Set();
      this.updateSearchType(0);
      this.updateQuerySearchType(14);
      this.searchText = "";
      this.searchKeywords = {
        any: [],
        all: [],
        none: [],
      };
      this.addedSearchKeywords = {
        any: [],
        all: [],
        none: [],
      };
      this.removedSearchKeywords = [];
      this.currentSavedSearchKeywords = [];
      this.chartData = {};
      this.topicsData = null;
      this.hasCaseBrand = false;
      this.generateOriginalHash();
    },

    createKeywords(profileId: string) {
      const keys: Array<"any" | "all" | "none"> = ["any", "all", "none"];
      keys.forEach((key: "any" | "all" | "none") => {
        const type = key === "any" ? 2 : key === "all" ? 3 : 4;
        this.addedSearchKeywords[key].forEach(async (keyword: string) => {
          const resp = await this.createSearchKeyword(profileId, keyword, type);
          const keywordData = resp.data._rawValue.data;
          this.currentSearchDashboardKeywords.push({
            id: keywordData.id,
            type: "profile_keyword",
            attributes: { keyword, keyword_type: type },
            relationships: {
              profile: { data: { id: profileId, type: "profile" } },
            },
          });
        });
      });
    },

    deleteKeywords() {
      this.removedSearchKeywords.forEach(async (keywordId: string) => {
        await this.deleteSearchKeyword(keywordId);
        this.currentSearchDashboardKeywords =
          this.currentSearchDashboardKeywords.filter(
            (keywordObj) => keywordObj.id !== keywordId,
          );
      });
    },

    generateOriginalHash() {
      // generate a hash of the current search data to check for changes
      this.originalSearchHash = hash({
        keywords: this.searchKeywords,
        searchtext: this.searchText,
        name: this.selectedSearch.name ? this.selectedSearch.name : "",
      });
    },

    generateOriginalLisenceLimitHash() {
      return hash(
        this.nestedSearches.map((search) => ({
          id: search.id,
          name: search.attributes.name,
        })),
      );
    },

    async checkLegacyBrandSearch(search: ComparisonSetItem) {
      // called when select search dashboard dropdown
      // do not load the search data into the store until users hit confirm

      this.legacyDataHolder = {};
      const nestedSearchData = await this.fetchNestedSearches(false, search.id);

      // get all profile_keywords for brand search
      const caseWords = nestedSearchData?.currSearchDashboardKeywords.filter(
        (keyword: SearchKeyword) => keyword.attributes.keyword_type === 1,
      );
      const uniqueNestedSearchIds = new Set(
        caseWords.map(
          (item: SearchKeyword) => item.relationships.profile.data.id,
        ),
      );

      // get all nested searches that are in simple mode AND have brand searches
      const legacyNestedSearchesWithCase =
        nestedSearchData?.nestedSearches.filter(
          (searches: SavedSearchItem) =>
            searches.attributes.search_type === 0 &&
            uniqueNestedSearchIds.has(searches.id),
        );

      if (legacyNestedSearchesWithCase.length > 0) {
        this.legacyDataHolder = {
          legacyNestedSearchesWithCase,
          searchDashboard: search,
        };
        this.showLegacyWarningDialog = true;
      } else {
        // business as usual, load up the search dashboard
        this.updateSearchSelection(search);
      }
    },

    async legacyWarningDialogConfirm() {
      try {
        if (this.legacyDataHolder.legacyNestedSearchesWithCase) {
          const patchPromises =
            this.legacyDataHolder.legacyNestedSearchesWithCase.map(
              async (nestedSearch: SavedSearchItem) => {
                return await this.patchNestedSearchType(nestedSearch);
              },
            );

          const results = await Promise.all(patchPromises);
          if (results.some((result: any) => result instanceof Error)) {
            throw new Error("patch operations failed.");
          }
          if (this.legacyDataHolder.searchDashboard) {
            this.updateSearchSelection(this.legacyDataHolder.searchDashboard);
            this.showLegacyWarningDialog = false;
            this.legacyDataHolder = {};
          } else {
            throw new Error("patch operations failed.");
          }
        } else {
          throw new Error("patch operations failed.");
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(
          "Legacy nested search convert to search type to 1 failed",
        );
      }
    },

    replaceCaseKeywords() {
      this.currentSavedSearchKeywords.forEach((keyword: SearchKeyword) => {
        if (
          keyword.attributes.keyword_type === 1 &&
          this.selectedNestedSearch.id
        ) {
          const currKeyword = keyword;
          this.deleteSearchKeyword(currKeyword.id);
          this.createSearchKeyword(
            this.selectedNestedSearch.id,
            keyword.attributes.keyword,
            3,
          );
        }
      });

      this.loadKeywords(
        this.currentSavedSearchKeywords,
        this.selectedNestedSearch.id,
      );
    },

    selectCurrentDashboardAndProfile() {
      const currentSearchDashboard = JSON.parse(
        localStorage.getItem("currentSearchDashboard") || "{}",
      );
      const currentComparisonSet = this.getComparisonSetById(
        currentSearchDashboard?.data?.id || -1,
      );

      if (currentComparisonSet) {
        this.updateSearchSelection(currentComparisonSet);
      } else if (this.searches[0]) {
        this.updateSearchSelection(this.searches[0]);
      }
    },

    async handleUpdateSearch() {
      // 1. Check if we're finalizing an unsaved nested search
      // 2. if it's not an unsaved nestsed search, check if it's adhoc
      //    a. if adhoc, CREATE/POST new search and save the filter
      //    b. if not, UPDATE/PATCH existing search
      try {
        if (this.querySearchType === 14 && this.isUnsavedNestedSearch) {
          const newNestedSearch = await this.createNestedSearch(
            this.selectedSearch.id,
          );
          if (newNestedSearch.data) {
            this.nestedSearches[this.nestedSearches.length - 1] =
              newNestedSearch.data;

            this.selectedNestedSearch = {
              ...newNestedSearch.data?.attributes,
              id: newNestedSearch.data?.id,
            };

            this.isUnsavedNestedSearch = false;
            this.updateQuerySearchType(0);
            this.createKeywords(newNestedSearch.data.id);
          }
        } else if (this.querySearchType === 14) {
          const searchFiltersStore = useSearchFiltersStore();
          const newSearch = await this.createNewSearch();
          if (newSearch.status === "error") throw new Error(newSearch?.message);

          const newNestedSearch = await this.createNestedSearch(
            newSearch.data.id,
          );
          if (newNestedSearch.status === "error") {
            throw new Error("create new nested search failed");
          }
          this.createKeywords(newNestedSearch.data.id);
          const primaryProfile = {
            id: newNestedSearch.data.id,
            type: "profile",
          };
          newSearch.data.relationships.primary_profile.data = primaryProfile;
          newSearch.data.relationships.profiles.data = [primaryProfile];
          this.searches.push(newSearch.data);
          this.$patch({ searches: this.searches.reverse() });
          await searchFiltersStore.createSearchFilter(
            `DEFAULT_${newSearch?.data?.attributes?.name}`,
            "profile",
            newNestedSearch.data.id,
            this.selectedSearch.id,
          );
          await this.updateSearchSelection(newSearch.data);
        } else {
          if (this.removedSearchKeywords.length > 0) {
            this.deleteKeywords();
          }
          if (this.selectedNestedSearch.id) {
            this.createKeywords(this.selectedNestedSearch.id);
          }
          const patchSearchResp = await this.patchSearch();
          if (patchSearchResp === "error")
            throw new Error("update search failed");

          const patchNestedSearchResp = await this.patchNestedSearch();
          if (patchNestedSearchResp === "error")
            throw new Error("update nested search failed");

          this.addedSearchKeywords = {
            any: [],
            all: [],
            none: [],
          };
          this.removedSearchKeywords = [];
          this.filterSavedSearchKeywords();
        }

        localStorage.removeItem("localKeywords");
        localStorage.removeItem("localBooleanData");

        localStorage.setItem(
          "currentProfile",
          JSON.stringify({
            data: { id: this.selectedNestedSearch.id, type: "profile" },
          }),
        );
        localStorage.setItem(
          "currentSearchDashboard",
          JSON.stringify({
            data: { id: this.selectedSearch.id, type: "search_dashboard" },
          }),
        );

        return "success";
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Error occured while saving search: ", e);
        return e.message;
      }
    },

    async fetchSearches() {
      const searchFiltersStore = useSearchFiltersStore();
      const exclusionStore = useExclusion();
      const options: RequestOptions = { method: "GET" };
      const resp = await useApiCall("/search_dashboards", options);
      this.searches = resp.data._rawValue.data.reverse();
      this.allOrgNestedSearches = resp.data._rawValue.included ?? [];
      this.allOrgNestedSearches?.forEach((obj) => {
        if (obj.type === "excluded_domain") {
          exclusionStore.addExcludedDomain(obj);
        }
      });
      this.currentTotalProfiles = resp.data._rawValue.meta.total_profiles;

      searchFiltersStore.addSearchFilters(
        resp.data._rawValue.included?.filter(
          (item: SearchFilter) => item?.type === "search_filter",
        ),
      );

      const pubs = resp.data._rawValue.included?.filter(
        (item: { type: string }) => item?.type === "excluded_domain",
      );
      const manageStore = useManageStore();
      manageStore.excludedPublications = pubs ?? [];
    },
    async fetchSearchFilters() {
      const searchFiltersStore = useSearchFiltersStore();
      const options: RequestOptions = { method: "GET" };
      const resp = await useApiCall(
        "/search_filters/search_filters_by_user",
        options,
      );
      if (resp.data._rawValue.data && resp.data._rawValue.data.length > 0) {
        searchFiltersStore.addSearchFilters(resp.data._rawValue.data);
      }
    },
    async fetchNestedSearches(
      loadToStore: boolean = true,
      selectedSearchId: string = "",
      useDefaultFilter: boolean = false,
    ) {
      const options: RequestOptions = { method: "GET" };
      const resp = await useApiCall(
        `/profiles/${
          selectedSearchId !== "" ? selectedSearchId : this.selectedSearch.id
        }/profiles_by_dashboard_id`,
        options,
      );
      localStorage.setItem(
        "nestedSearches",
        JSON.stringify(resp.data._rawValue.data),
      );
      if (resp.data._rawValue.data[0]?.relationships.search_dashboard.data) {
        localStorage.setItem(
          "currentSearchDashboard",
          JSON.stringify({
            data: resp.data._rawValue.data[0].relationships.search_dashboard
              .data,
          }),
        );
      }

      const storedProfile = JSON.parse(
        localStorage.getItem("currentProfile") as string,
      );
      const isStoredProfileValid = resp.data._rawValue.data.includes(
        (pro) => pro.id === storedProfile?.data?.id,
      );
      const currentProfile = resp.data._rawValue.data.find(
        (pro) => pro.id === storedProfile?.data?.id,
      );
      if (resp.data._rawValue.data[0]?.id && !isStoredProfileValid) {
        localStorage.setItem(
          "currentProfile",
          JSON.stringify({
            data: { id: resp.data._rawValue.data[0]?.id, type: "profile" },
          }),
        );
      }

      if (loadToStore) {
        // load to store as normal
        this.nestedSearches = resp.data._rawValue.data;
        this.originalLicenseLimitHash = this.generateOriginalLisenceLimitHash();
        this.currentSearchDashboardKeywords =
          resp.data._rawValue.included?.filter(
            (item: SearchKeyword) => item.type === "profile_keyword",
          );
        this.currentSelectedSearchDashboard = this.selectedSearch.id || null;

        this.nestedSearchesFilters = (
          resp.data._rawValue.included || []
        ).filter((item: SearchFilter) => item?.type === "search_filter");
        const searchFiltersStore = useSearchFiltersStore();
        if (useDefaultFilter) {
          searchFiltersStore.setCurrentSearchFilter(
            searchFiltersStore.defaultSearchFilter,
            false,
          );
        } else {
          searchFiltersStore.addSearchFilters(this.nestedSearchesFilters);
          this.setDefaultSearchFilters();
        }

        this.updateSelectedNestedSearch(
          currentProfile || resp.data._rawValue.data[0],
        );
        this.fetchAllNestedSearches();
      } else {
        // return nested search data for legacy handling
        return {
          nestedSearches: resp.data._rawValue.data,
          currSearchDashboardKeywords: resp.data._rawValue.included?.filter(
            (item: SearchKeyword) => item.type === "profile_keyword",
          ),
        };
      }
    },

    async fetchDashboardCharts() {
      const chartsStore = useChartsStore();
      chartsStore.dashboardChartsData = [];
      await chartsStore.fetchDashboardChartData();
    },

    async articleSearchRequest() {
      this.isLoading = true;
      if (!this.isPaginated) {
        this.page = 1;
      }

      if (this.onChartsPageCheck) {
        // fetch charts instead of articles for charts page
        await this.fetchDashboardCharts();
        this.updateQuerySearchType(1);
        return;
      } else if (this.onMentionsPageCheck) {
        // get chart data for mentions page
        await this.fetchDashboardCharts();
        this.updateQuerySearchType(0);
      }

      if (this.searchType === 0) {
        this.generateSearchText();
        const generated = this.searchText;

        // If no search text is generated, clear results + don't make a request to the crawler
        if (!generated) {
          this.updateMentions([]);
          this.total = 0;
          this.isLoading = false;
          return;
        }
      }

      const reqQuery = useRequestQuery("article-searches");
      const options: RequestOptions = { method: "POST", body: reqQuery };

      const searchResponse: {
        data: {
          value: {
            data: {
              attributes: {
                search_result: { articles: MentionList; total: number };
              };
            };
          };
        };
      } = await useApiCall("/article_searches", options);

      const mentions =
        searchResponse.data.value?.data?.attributes?.search_result?.articles ??
        [];
      this.total =
        searchResponse.data.value?.data?.attributes?.search_result?.total ?? 0;
      this.updateMentions(mentions);

      // We only want to fetch charts on the mentions page
      if (this.onMentionsPageCheck) {
        const temp = this.querySearchType;
        this.updateQuerySearchType(1);
        this.fetchChartData();
        this.updateQuerySearchType(temp);
      }

      // We only want to fetch topics data on the topics page
      if (this.onTopicsPageCheck) {
        const temp = this.querySearchType;
        this.updateQuerySearchType(3);
        this.fetchTopicSearchesData();
        this.updateQuerySearchType(temp);
      }

      this.isLoading = false;
    },

    async updateArticle(articleId: string) {
      const reqQuery = useRequestQuery("article-searches");
      reqQuery.data.attributes.search_text = "*";
      reqQuery.data.attributes.page = 1;
      reqQuery.data.attributes.article_id = articleId;
      const options: RequestOptions = { method: "POST", body: reqQuery };

      const searchResponse: {
        data: {
          value: {
            data: {
              attributes: {
                search_result: { articles: MentionList; total: number };
              };
            };
          };
        };
      } = await useApiCall("/article_searches", options);

      if (
        searchResponse.data.value.data.attributes.search_result.articles
          .length > 0
      ) {
        const mention =
          searchResponse.data.value.data.attributes.search_result.articles[0];
        this.updateMention(mention);
      }
    },

    async articleOpenTracker(
      mentionId: string,
      openType: string,
      mentionType: string,
      unlocked: boolean | undefined = undefined,
    ) {
      const options: RequestOptions = {
        method: "POST",
        body: {
          crawler_article_id: mentionId,
          open_type: openType,
          media_type: mentionType,
          search_dashboard_id: this.currentSelectedSearchDashboard,
          profile_id: this.currentSelectedProfile,
        },
      };

      if (options.body && unlocked !== undefined) {
        options.body.unlocked = unlocked;
      }

      const res = await useApiCall(`/article_open_trackers`, options);

      return res;
    },

    async updateUsersCurrentInfo(searchDashboardId: string, profileId: string) {
      const org = process.client
        ? JSON.parse(localStorage.getItem("organization") || "{}")
        : null;

      const user = process.client
        ? JSON.parse(localStorage.getItem("user") || "{}")
        : null;

      // these fields need to be removed or the request will fail :(
      delete user.is_impersonated;
      delete user.jwt_token;
      delete user.expires_at;
      delete user.role_name;
      delete user.created_at;
      delete user.accepted_invite;
      delete user.intercom_hash;

      const data = {
        attributes: { ...user },
        id: String(user.id),
        relationships: {
          organization: {
            data: { type: "organizations", id: String(org.id) },
          },
          current_profile: {
            data: { type: "profiles", id: profileId },
          },
          current_search_dashboard: {
            data: { type: "search-dashboards", id: searchDashboardId },
          },
        },
        type: "users",
      };

      delete data.attributes.id;

      const options: RequestOptions = { method: "PATCH", body: { data } };

      await useApiCall(`/users/${user.id}`, options);
    },

    async fetchChartData() {
      const requestQuery = await useRequestQuery("chart-searches");
      const options: RequestOptions = {
        method: "POST",
        body: requestQuery,
      };
      const resp = await useApiCall("/chart_searches", options);
      this.chartData = resp.data._rawValue.data;
    },

    async fetchTopicSearchesData() {
      this.isTopicsDataLoading = true;
      const requestQuery = await useRequestQuery("topic-searches");
      const options: RequestOptions = {
        method: "POST",
        body: requestQuery,
      };
      const resp = await useApiCall("/topic_searches", options);
      this.topicsData = resp.data._rawValue.data;
      this.isTopicsDataLoading = false;
    },

    // profile keywords
    async createSearchKeyword(
      profileId: string,
      profileKeyword: string,
      keywordType: number,
    ) {
      const options: RequestOptions = {
        method: "POST",
        body: {
          data: {
            attributes: {
              keyword: profileKeyword,
              keyword_type: keywordType,
            },
            relationships: {
              profile: {
                data: {
                  id: profileId,
                  type: "profiles",
                },
              },
            },
            type: "profile-keywords",
          },
        },
      };

      return await useApiCall("/profile_keywords", options);
    },

    async deleteSearchKeyword(keywordId: string) {
      const options: RequestOptions = {
        method: "DELETE",
      };

      await useApiCall(`/profile_keywords/${keywordId}`, options);
    },

    // search dashboards
    async createNewSearch() {
      const options: RequestOptions = {
        method: "POST",
        body: {
          data: {
            attributes: {
              name: this.selectedSearch.name,
              search_type: this.searchType,
            },
            type: "search-dashboards",
          },
        },
      };
      const resp = await useApiCall("/search_dashboards", options);
      return {
        data: resp.data._rawValue?.data,
        status: resp.status.value,
        message: resp.error?.value?.data?.errors[0]?.detail,
      };
    },
    async patchSearch() {
      const orgStore = useOrgStore();
      const options: RequestOptions = {
        method: "PATCH",
        body: {
          data: {
            id: this.selectedSearch.id,
            attributes: {
              name: this.selectedSearch.name,
              search_type: this.selectedSearch.search_type,
              social_tracker_enabled:
                this.selectedSearch.social_tracker_enabled,
              social_tracker_enabled_at:
                this.selectedSearch.social_tracker_enabled_at,
              is_comparison: this.selectedSearch.is_comparison,
              analytics_chart_template_id:
                this.selectedSearch.analytics_chart_template_id,
            },
            relationships: {
              organization: {
                data: {
                  type: "organizations",
                  id: orgStore.getOrg?.id,
                },
              },
              primary_profile: {
                data: {
                  type: "profiles",
                  id: this.selectedSearch.primaryProfileId,
                },
              },
              // TODO: Add this relationship once we have access to the id
              // search_filter: {
              //   data: {
              //     type: "search-filters",
              //     id: "1154",
              //   },
              // },
              // TODO: not sure if we need this but we can pass it once we have access
              // twitter_amp_setting: {
              //   data: null,
              // },
            },
            type: "search-dashboards",
          },
        },
      };
      const resp = await useApiCall(
        `/search_dashboards/${this.selectedSearch.id}`,
        options,
      );
      const replaceIdx = this.searches.findIndex(
        (search: any) =>
          Number(search.id) === Number(resp.data._rawValue.data.id),
      );

      this.searches[replaceIdx] = resp.data._rawValue.data;
      this.generateOriginalHash();
      return resp.status.value;
    },

    // profiles
    async createNestedSearch(dashboardId: string = ""): Promise<any> {
      let searchName = this.selectedNestedSearch?.name;

      if (!searchName) {
        searchName = this.selectedSearch.name;
      }

      const options: RequestOptions = {
        method: "POST",
        body: {
          data: {
            attributes: {
              configure_digest: false,
              is_primary_profile: this.isUnsavedNestedSearch
                ? false
                : Boolean(dashboardId),
              name: searchName,
              profile_search_text: dashboardId ? this.searchText : "",
              profile_type: 1,
              search_type: dashboardId ? this.searchType : 0,
            },
            relationships: {
              search_dashboard: {
                data: {
                  id: dashboardId || this.selectedSearch.id,
                  type: "search-dashboards",
                },
              },
            },
            type: "profiles",
          },
        },
      };
      const resp = await useApiCall("/profiles", options);
      if (resp.data._rawValue === null) {
        return resp.error;
      }

      this.currentTotalProfiles += 1;
      return {
        data: resp.data._rawValue.data,
        status: resp.status.value,
      };
    },

    patchNestedSearch() {
      const orgStore = useOrgStore();
      const options = {
        method: "PATCH",
        body: {
          data: {
            id: this.selectedNestedSearch.id,
            attributes: {
              name: this.selectedNestedSearch.name,
              profile_type: this.selectedNestedSearch.profile_type,
              search_type: this.searchType,
              profile_search_text: this.searchText,
              stock_exchange: this.selectedNestedSearch.stock_exchange,
              stock_symbol: this.selectedNestedSearch.stock_symbol,
              relevance_thresholds_updated_at:
                this.selectedNestedSearch.relevance_thresholds_updated_at,
              is_primary_profile: this.selectedNestedSearch.is_primary_profile,
              // defaulting to this, don't currently have access to this field
              configure_digest: false,
            },
            relationships: {
              organization: {
                data: { type: "organizations", id: orgStore.getOrg?.id },
              },
              // TODO: Add this relationship once we have access to the id
              // search_filter: { data: { type: "search-filters", id: null } },
              search_dashboard: {
                data: { type: "search-dashboards", id: this.selectedSearch.id },
              },
            },
            type: "profiles",
          },
        },
      };

      const resp = this.nestedSearchPatchRequest(options);
      return resp;
    },

    patchNestedSearchType(search: SavedSearchItem) {
      // force the search into boolean mode: search_type 1

      // strip the CASE from nested search
      if ((search.attributes.profile_search_text || "").startsWith("CASE")) {
        const trimmedString = (search.attributes.profile_search_text || "")
          .slice("CASE".length)
          .trim();
        search.attributes.profile_search_text = trimmedString;
      }

      const orgStore = useOrgStore();
      const options = {
        method: "PATCH",
        body: {
          data: {
            id: search.id,
            attributes: {
              name: search.attributes.name,
              profile_type: search.attributes.profile_type,
              search_type: 1,
              profile_search_text: search.attributes.profile_search_text,
              stock_exchange: search.attributes.stock_exchange,
              stock_symbol: search.attributes.stock_symbol,
              relevance_thresholds_updated_at:
                search.attributes.relevance_thresholds_updated_at,
              is_primary_profile: search.attributes.is_primary_profile,
              // defaulting to this, don't currently have access to this field
              configure_digest: false,
            },
            relationships: {
              organization: {
                data: { type: "organizations", id: orgStore.getOrg?.id },
              },
              // TODO: Add this relationship once we have access to the id
              // search_filter: { data: { type: "search-filters", id: null } },
              search_dashboard: {
                data: {
                  type: "search-dashboards",
                  id: this.legacyDataHolder?.searchDashboard?.id,
                },
              },
            },
            type: "profiles",
          },
        },
      };

      const resp = this.nestedSearchPatchRequest(options, search.id);
      return resp;
    },

    async nestedSearchPatchRequest(options: Object, searchId: string = "") {
      const resp = await useApiCall(
        `/profiles/${
          searchId !== "" ? searchId : this.selectedNestedSearch.id
        }`,
        options,
      );

      const replaceIdx = this.nestedSearches.findIndex(
        (search: any) =>
          Number(search.id) === Number(resp.data._rawValue.data.id),
      );

      this.nestedSearches[replaceIdx] = resp.data._rawValue.data;

      return resp.status.value;
    },

    // TODO: there is no where to hook this up to in the UI currently
    async deleteSearchDashboard() {
      const options: RequestOptions = {
        method: "DELETE",
      };
      try {
        await useApiCall(
          `/search_dashboards/${this.selectedSearch.id}`,
          options,
        );

        // filter out the deleted search + select new one
        this.searches = this.searches.filter(
          (search) => search.id !== this.selectedSearch.id,
        );
        this.updateSearchSelection(this.searches[0]);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },

    async deleteSearchDashboardById(dashboardId: string) {
      const options: RequestOptions = {
        method: "DELETE",
      };
      try {
        const resp = await useApiCall(
          `/search_dashboards/${dashboardId}`,
          options,
        );

        // filter out the deleted search
        this.searches = this.searches.filter(
          (search) => search.id !== dashboardId,
        );
        return resp;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },

    async deleteNestedSearch() {
      const options: RequestOptions = {
        method: "DELETE",
      };
      try {
        if (!this.isUnsavedNestedSearch) {
          await useApiCall(
            `/profiles/${this.selectedNestedSearch.id}`,
            options,
          );
        }

        // filter out the deleted search + select new one
        this.nestedSearches = this.nestedSearches.filter(
          (nestedSearch) => nestedSearch.id !== this.selectedNestedSearch.id,
        );
        this.updateSelectedNestedSearch(this.nestedSearches[0]);

        localStorage.setItem(
          "currentProfile",
          JSON.stringify({
            data: { id: this.nestedSearches[0].id, type: "profile" },
          }),
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return false;
      }
      return true;
    },

    async deleteNestedSearchById(searchId: string) {
      const options: RequestOptions = {
        method: "DELETE",
      };
      try {
        const resp = await useApiCall(`/profiles/${searchId}`, options);

        // filter out the deleted search
        this.allOrgNestedSearches = this.allOrgNestedSearches.filter(
          (profile) => profile.id !== searchId,
        );
        return resp;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },

    _resetSet(s: Set<any>, key: string) {
      /*
        There is probably a better way to do this,
        but resetting the set's state as a new set
        is necessary for maintaining reactivity.
      */
      this[key] = new Set(s);
    },

    async fetchAllNestedSearches() {
      try {
        let profiles = [];
        const response = await useApiCall(`/profiles/`, { method: "GET" });

        if (response) {
          profiles = response.data._rawValue.data;

          // TODO: remove this once this field is gone
          profiles = profiles.filter(
            (profile: any) =>
              !profile.attributes.hasOwnProperty("license_limit") ||
              profile.attributes.license_limit >= 0,
          );
        }
        this.allOrgNestedSearches = profiles;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },

    async excludeMention() {
      const curProfileId = this.selectedNestedSearch.id;

      const options = {
        method: "POST",
        body: {
          data: {
            attributes: this.excludeMentionData,
            relationships: {
              profile: { data: { type: "profiles", id: curProfileId } },
            },
            type: "excluded-articles",
          },
        },
      };

      const results = await useApiCall("/excluded_articles", options);

      const manageStore = useManageStore();
      if (results.error?.value) {
        if (
          results.error.value?.cause?.data?.errors &&
          String(results.error.value?.cause?.data?.errors[0]?.code).includes(
            "you_have_reached_the_excluded_article_limit_for_your_plan_please_check_your_settings_page_for_current_available_limits",
          )
        ) {
          manageStore.excludeMentionErrorKey =
            "manage.mentionsAndPublications.excludeMentionLimitError";
        } else {
          manageStore.excludeMentionErrorKey =
            "manage.mentionsAndPublications.excludeMentionFailure";
        }
        return {
          errMsg: manageStore.excludeMentionErrorKey,
        };
      } else {
        this.showExcludeDialog = false;
        this.mentionExclusionNotification = true;
        this.excludeMentionData = {};
        this.articleSearchRequest();
      }
    },
  },
});
